import {Button, Dropdown, DropdownItem, RadioGroup, UnderlinedHeader,} from "src/components";
import {NO_OP} from "src/constants/common";
import {Member, PoaType, PowerOfAppointment, TransferType,} from "../EstateFlowchartTypes";
import React, {ChangeEvent, useState} from "react";

type PowerOfAppointmentRowProps = {
    powerOfAppointmentForm: PowerOfAppointment;
    onChangePOA: (updatedPOA: PowerOfAppointment) => void;
    index: number;
    familyMembers: Member[];
};
export default function PowerOfAppointmentRow({
                                                  powerOfAppointmentForm,
                                                  onChangePOA,
                                                  index,
                                                  familyMembers,

                                              }: PowerOfAppointmentRowProps) {
    const [powerOfAppointment, setPowerOfAppointmentForm] =
        useState<PowerOfAppointment>(powerOfAppointmentForm);
    return (
        <div>
            <UnderlinedHeader
                leftAlignedContent={
                    <p className="power-of-appointment-header">
                        POWER OF APPOINTMENT {powerOfAppointmentForm.poaOrder + 1}
                    </p>
                }
                rightAlignedContent={
                    <Button
                        ariaLabel="delete"
                        onClick={NO_OP}
                        icon="left"
                        iconName="delete"
                        kind="borderless"
                    >
                        Delete{" "}
                    </Button>
                }
            />
            <div className="layout-data-entry-form__field">
                <label className={"h5"}>Type</label>
                <RadioGroup
                    name={`type-${index}`}
                    label={"poa-type"}
                    layout="vertical"
                    className="vertical-radiogroup"
                    values={["Limited", "General"]}
                    selected={powerOfAppointment.type}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const updatedPOA = {
                            ...powerOfAppointment,
                            type: e.target.value as PoaType,
                        };
                        setPowerOfAppointmentForm(updatedPOA);
                        onChangePOA(updatedPOA);
                    }}
                />
            </div>

            <div className="layout-data-entry-form__field">
                <label className={"h5"}>Transfer Type</label>
                <RadioGroup
                    name={`transferType-${index}`}
                    label={"poa-transfer-type"}
                    layout="vertical"
                    className="vertical-radiogroup"
                    values={["Testamentary", "Lifetime"]}
                    selected={powerOfAppointment.transferType}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const updatedPOA = {
                            ...powerOfAppointment,
                            transferType: e.target.value as TransferType,
                        };
                        setPowerOfAppointmentForm(updatedPOA);
                        onChangePOA(updatedPOA);
                    }}
                />
            </div>

            <div
                className="layout-data-entry-form__field"
                data-testid="power-holder-dropdown"
            >
                <label className={"h5"}>Power Holder</label>
                <Dropdown
                    className="power-holder-dropdown"
                    onChange={(e) => {
                        setPowerOfAppointmentForm({
                            ...powerOfAppointment,
                            powerHolder: e.value,
                        });
                        onChangePOA({...powerOfAppointment, powerHolder: e.value});
                    }}
                    size="medium"
                    iconNameClose="arrow_drop_up"
                    iconNameOpen="arrow_drop_down"
                    id="powerHolder"
                    name="powerHolder"
                    value={
                        powerOfAppointment.powerHolder ? powerOfAppointment.powerHolder : ""
                    }
                >
                    {familyMembers.map((member: Member) => (
                        <DropdownItem
                            key={member.value}
                            value={member.value}
                            itemText={member.label}
                        />
                    ))}
                </Dropdown>
            </div>

            <div className="layout-data-entry-form__field basic-information__distribution">
                <div className={"textarea-label"}>
                    <label className={"h5"}>Permissible Appointees</label>
                    <span className="textarea-limit-count">
            {powerOfAppointment.permissibleAppointees ? powerOfAppointment.permissibleAppointees.length : 0}/500
          </span>
                </div>
                <textarea
                    name="permissibleAppointees"
                    className={"textarea-500-limit input-skin"}
                    data-testid="permissible-appointees-field"
                    autoFocus={false}
                    rows={5}
                    cols={50}
                    maxLength={500}
                    defaultValue={powerOfAppointment.permissibleAppointees}
                    onChange={(e) => {
                        setPowerOfAppointmentForm({
                            ...powerOfAppointment,
                            permissibleAppointees: e.target.value,
                        });
                        onChangePOA({
                            ...powerOfAppointment,
                            permissibleAppointees: e.target.value,
                        });
                    }}
                />
                <span className="input__info margintop-sm">
          {"Eg: Descendents, charity, sometimes spouse of descendents/ " +
              "(three most common in that order)"}
        </span>
            </div>

            <div className="layout-data-entry-form__field basic-information__distribution">
                <div className={"textarea-label"}>
                    <label className={"h5"}>Additional Details</label>
                    <span className="textarea-limit-count">
            {powerOfAppointment.additionalDetails ? powerOfAppointment.additionalDetails.length : 0}/500
          </span>
                </div>
                <textarea
                    name="additionalDetails"
                    className="textarea-500-limit input-skin"
                    data-testid="additional-details-field"
                    autoFocus={false}
                    rows={5}
                    cols={50}
                    maxLength={500}
                    defaultValue={powerOfAppointment.additionalDetails}
                    onChange={(e) => {
                        setPowerOfAppointmentForm({
                            ...powerOfAppointment,
                            additionalDetails: e.target.value,
                        });
                        onChangePOA({
                            ...powerOfAppointment,
                            additionalDetails: e.target.value,
                        });
                    }}
                />
                <span className="input__info margintop-sm">
                    {"Eg: at #age get % amount"}
                </span>
            </div>

        </div>
    );
}
