import React, {useState} from "react";
import ComparePlansHeader from "./ComparePlansHeader";
import ComparePlansContent from "./ComparePlansContent";

interface ComparePlansProps {
    showDeltaBetweenPlans: boolean;
}

export const ComparePlans: React.FC<ComparePlansProps> = () => {
    const [showDeltaBetweenPlans, setShowDeltaBetweenPlans] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<boolean>(false);

    const handleShowDeltaBetweenPlans = (isChecked : boolean) => {
        setShowDeltaBetweenPlans(isChecked);
    }

    const handleComparePlanDropDownChange = (isComparePlan2Selected : boolean) => {
        setSelectedValue(isComparePlan2Selected);
    }

    return (
        <div className="compare-plans">
            <ComparePlansHeader value={showDeltaBetweenPlans} onChange={handleShowDeltaBetweenPlans} selectedValue={selectedValue}/>
            <>
                <ComparePlansContent value={showDeltaBetweenPlans} onChange={handleComparePlanDropDownChange}/>
            </>
        </div>
    )
}