import {useParams} from "react-router-dom";
import {AddDistributionButtonDropdown} from "./AddDistributionButtonDropdown";
import {RouteWithIdAndFlowchartId} from "src/routes/types";
import {useAppSelector} from "src/store/hooks";
import {getEstateFlowcharts} from "../EstateFlowChartSlice";
import {Beneficiaries, RevocableTrust} from "../EstateFlowchartTypes";
import React, {useEffect, useState} from "react";
import MaritalTrustForm from "./MaritalTrustForm";
import {isValid} from "./validations";

type BeneficiariesFirstDeathContentProps = {
    updatedRevocableTrust: Function,
    saveHasBeenClicked: boolean
}

/**
 * Component for displaying and managing the beneficiaries at the first death in an estate flowchart.
 *
 * @component
 * @param {BeneficiariesFirstDeathContentProps} props - The properties for the component.
 * @param {Function} props.updatedRevocableTrust - Function to update the revocable trust with new beneficiary information.
 * @param {boolean} props.saveHasBeenClicked - Flag indicating if the save button has been clicked.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <BeneficiariesFirstDeathContent
 *   updatedRevocableTrust={updatedRevocableTrustFunction}
 *   saveHasBeenClicked={true}
 * />
 *
 * @remarks
 * This component uses the `useParams` hook to extract route parameters and the `useAppSelector` hook to access the estate flowcharts from the state.
 * It initializes the beneficiary information based on the revocable trust data and updates the state accordingly.
 * The component conditionally renders either a `MaritalTrustForm` or an `AddDistributionButtonDropdown` based on the presence of beneficiary information.
 *
 */
const BeneficiariesFirstDeathContent: React.FC<BeneficiariesFirstDeathContentProps> =
    ({updatedRevocableTrust, saveHasBeenClicked}: BeneficiariesFirstDeathContentProps): JSX.Element => {
        const {flowchartId} = useParams<RouteWithIdAndFlowchartId>();
        const estateFlowchartsInState = useAppSelector(getEstateFlowcharts);
        const [beneficiaryInformation, updateBeneficiaryInformation] = useState({} as Beneficiaries);
        const buildInitialForm = () => {
            const initializeTrust = (revocableTrust: RevocableTrust | undefined) => {
                if (revocableTrust?.beneficiary) {
                    updateBeneficiaryInformation(revocableTrust.beneficiary);
                }
            };

            if (estateFlowchartsInState.length > 0) {
                const revocableTrust = estateFlowchartsInState.find(
                    flowchart => flowchart.flowchartId === flowchartId)?.revocableTrust;
                initializeTrust(revocableTrust);
            }
        };

        useEffect(() => {
            buildInitialForm();
        }, [estateFlowchartsInState]);

        useEffect(() => {
            const revocableTrust = estateFlowchartsInState.find(flowchart => flowchart.flowchartId === flowchartId)?.revocableTrust;
            updatedRevocableTrust({...revocableTrust, beneficiary: beneficiaryInformation});
        }, [beneficiaryInformation])

        const content = () => {
            const revocableTrust = estateFlowchartsInState.find(flowchart => flowchart.flowchartId === flowchartId)?.revocableTrust;
            if (revocableTrust && beneficiaryInformation.firstDeath) {
                return (
                    <>
                    <MaritalTrustForm beneficiariesInformation={beneficiaryInformation}
                                      saveHasBeenClicked={saveHasBeenClicked}
                                      isValid={isValid}
                                      updateBeneficiaryInformation={updateBeneficiaryInformation}/>

                    <div>
                        <h2 className={"add-copy-trust-heading"}>Continue to add or copy distributions</h2>
                        <AddDistributionButtonDropdown className={"add-distribution-button"}
                                               updateBeneficiaryInformation={updateBeneficiaryInformation}/>
                    </div>
                    </>
                )
            } else {
                return (
                    <AddDistributionButtonDropdown className={"add-distribution-button"}
                                                   updateBeneficiaryInformation={updateBeneficiaryInformation}/>
                )
            }
        }

        return (
            <div className="beneficiary-first-death-form">
                <div className="header">
                    <h1>Beneficiaries at First Death</h1>
                    <h6>Begin by adding new trusts or distributions to be created upon death of first grantor.</h6>
                </div>
                {content()}
            </div>
        )
    }
export default BeneficiariesFirstDeathContent;