import React from "react";
import {Col, Row} from "react-grid-system";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import RiskDonut from "../../ClientManagement/AssetAllocation/RiskDonut";
import {CHART_TITLE_CELL_WIDTH, TITLE_CELL_WIDTH} from "./ComparePlansContent";
import {formatNumberRoundedToWholeNumber} from "../../utils/format";
import {COLOR_COMPARE_PLANS_EXCESS, COLOR_COMPARE_PLANS_SHORTFALL} from "../../constants/colors";


interface ComparePlansRiskDonutProps {
    comparePlan1PlanSummaryResponse: PlanSummaryResponse;
    comparePlan2PlanSummaryResponse: PlanSummaryResponse;
    showPillText?: boolean;
}

const ComparePlansRiskDonut: React.FC<ComparePlansRiskDonutProps> = ({
                                                                         comparePlan1PlanSummaryResponse,
                                                                         comparePlan2PlanSummaryResponse,
                                                                         showPillText
                                                                     }) => {
    let pillTextValue;
    return (
        <Row style={{marginTop: 15}}>
            <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
                {comparePlan2PlanSummaryResponse == null && <hr className={"compare-plans-empty-state-hr"}/>}
                <div className="compare-plans-sub-header">Proposed Asset Allocation</div>
                <div className='subhead'>Total Portfolio</div>
            </Col>
            <span className="divider"></span>
            <Col md={CHART_TITLE_CELL_WIDTH}>
                <Row>
                    <Col>
                        <div style={{paddingLeft: 100, marginTop: 25}}>
                            <RiskDonut
                                donutSize={'xsm'}
                                data={comparePlan1PlanSummaryResponse!.proposedAllocation}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div style={{marginLeft: -20}} className={"risk-assets-and-risk-control"}>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"}>
                                    <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={'RA'}/>
                                    <span
                                        className={"risk-percentage"}><b>{` ${formatNumberRoundedToWholeNumber(comparePlan1PlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent)}%`}</b></span>

                                </div>

                            </div>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"}>
                                    <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}
                                                label={'RCA'}/>
                                    <span
                                        className={"risk-percentage"}><b>{` ${formatNumberRoundedToWholeNumber(comparePlan1PlanSummaryResponse!.proposedAllocation.totalRiskControlAssetsPercent)}%`}</b></span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
            <span className="divider"></span>
            <Col md={CHART_TITLE_CELL_WIDTH}>
                {comparePlan2PlanSummaryResponse != null &&
                    <Row>
                        <Col>
                            <div style={{paddingLeft: 100, marginTop: 25}}>
                                <RiskDonut
                                    donutSize={'xsm'}
                                    data={comparePlan2PlanSummaryResponse!.proposedAllocation}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div style={{marginLeft: -20}} className={"risk-assets-and-risk-control"}>
                                <div className={"risk"}>
                                    <div className={"risk-legend-label"}>
                                        <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={'RA'}/>
                                        <span
                                            className={"risk-percentage"}><b>{` ${formatNumberRoundedToWholeNumber(comparePlan2PlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent)}%`}</b></span>
                                        {showPillText && (comparePlan2PlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent - comparePlan1PlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent) != 0 &&
                                            <span
                                                className={(comparePlan2PlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent - comparePlan1PlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent) >= 0 ? "compare-plans-pill-container pill-container-excess position-absolute" : "compare-plans-pill-container pill-container-shortfall position-absolute"}
                                                style={{
                                                    color: (comparePlan2PlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent - comparePlan1PlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent) >= 0 ? COLOR_COMPARE_PLANS_EXCESS : COLOR_COMPARE_PLANS_SHORTFALL,
                                                }}>
                                                <span
                                                    className="pill-text"> {`${formatNumberRoundedToWholeNumber(comparePlan2PlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent - comparePlan1PlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent)}%`}</span>
                                            </span>
                                        }
                                    </div>

                                </div>
                                <div className={"risk"}>
                                    <div className={"risk-legend-label"}>
                                        <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}
                                                    label={'RCA'}/>
                                        <span
                                            className={"risk-percentage"}><b>{` ${formatNumberRoundedToWholeNumber(comparePlan2PlanSummaryResponse!.proposedAllocation.totalRiskControlAssetsPercent)}%`}</b></span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                }

            </Col>
        </Row>
    );
}

export default ComparePlansRiskDonut;