import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../components";
import {formatCurrencyRounded} from "../../utils/format";

export class ExcessAssetsDisplayTableForComparePlans extends TableDisplay<number, TableRow<number>> {
    private readonly excessAssets: number;
    private readonly proposalExcessAssets: number;
    private readonly differenceInExcessAssets: number | String;
    private readonly showDeltaBetweenPlans: boolean;
    private readonly isEmptyState: boolean;

    constructor(columnCounter: ColumnCounter, excessAssets: number, proposalExcessAssets: number, differenceInExcessAssets: number | String, showDeltaBetweenPlans: boolean, isEmptyState: boolean) {
        super(columnCounter);
        this.excessAssets = excessAssets;
        this.proposalExcessAssets = proposalExcessAssets;
        this.differenceInExcessAssets = differenceInExcessAssets;
        this.showDeltaBetweenPlans = showDeltaBetweenPlans;
        this.isEmptyState = isEmptyState;
    }

    protected get headerLabel(): string {
        if (this.excessAssets < 0 || this.proposalExcessAssets < 0) {
            return "Asset Shortfall";
        } else {
            return "Excess Assets";
        }
    }

    protected get headerValues(): TableCell<number>[] {
        const profileNetAssets = {
            originalValue: this.excessAssets,
            renderedValue: formatCurrencyRounded(this.excessAssets, 0),
            isBlank: false,
            className: this.excessAssets < 0 ? 'negative-difference' : undefined}
        const proposalNetAssets = {
            originalValue: this.proposalExcessAssets,
            renderedValue: formatCurrencyRounded(this.proposalExcessAssets, 0),
            isBlank: false,
            className: this.proposalExcessAssets < 0 ? 'negative-difference' : undefined,
            renderedSecondaryValue: this.showDeltaBetweenPlans ? this.differenceInExcessAssets?.toString() : undefined
        }
        return this.isEmptyState ? [profileNetAssets] : [profileNetAssets, proposalNetAssets];
    }

    get rows(): TableRow<number>[] {
        return [{
            accentColor: '',
            children: [],
            label: "",
            uniqueIdentifier: '',
            values: [{
                originalValue: this.excessAssets,
                renderedValue: this.excessAssets?.toString(),
                isBlank: false,
            }, {
                originalValue: this.proposalExcessAssets,
                renderedValue: this.proposalExcessAssets?.toString(),
                renderedSecondaryValue: this.showDeltaBetweenPlans ? this.differenceInExcessAssets?.toString() : undefined,
                isBlank: false
            }]
        }]
            ;
    }
}