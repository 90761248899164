import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../components";
import {formatCurrencyRounded} from "../../utils/format";

export class GoalsTableDisplayForComparePlan extends TableDisplay<number, TableRow<number>> {
    private readonly goalsTotalPresentValue: number;
    private readonly proposalGoalsTotalPresentValue: number;
    private readonly differenceInGoalsTotalPresentValue: number | String;
    private readonly showDeltaBetweenPlans: boolean;
    private readonly isEmptyState: boolean;

    constructor(columnCounter: ColumnCounter, goalsTotalPresentValue: number, proposalGoalsTotalPresentValue: number, differenceInGoalsTotalPresentValue: number | String, showDeltaBetweenPlans: boolean, isEmptyState: boolean) {
        super(columnCounter);
        this.goalsTotalPresentValue = goalsTotalPresentValue;
        this.proposalGoalsTotalPresentValue = proposalGoalsTotalPresentValue;
        this.differenceInGoalsTotalPresentValue = differenceInGoalsTotalPresentValue;
        this.showDeltaBetweenPlans = showDeltaBetweenPlans;
        this.isEmptyState = isEmptyState;
    }

    protected get headerLabel(): string {
        return "Goals";
    }

    protected get headerValues(): TableCell<number>[] {
        const profileNetAssets = {
            originalValue: this.goalsTotalPresentValue,
            renderedValue: formatCurrencyRounded(this.goalsTotalPresentValue,0),
            isBlank: false}
        const proposalNetAssets = {
            originalValue: this.proposalGoalsTotalPresentValue,
            renderedValue: formatCurrencyRounded(this.proposalGoalsTotalPresentValue, 0),
            renderedSecondaryValue: this.showDeltaBetweenPlans ? this.differenceInGoalsTotalPresentValue?.toString() : undefined,
            isBlank: false}

        return this.isEmptyState ? [profileNetAssets] : [profileNetAssets, proposalNetAssets];
    }

    get rows(): TableRow<number>[] {
        return [{
            accentColor: '',
            children: [],
            label: "Total Present Value",
            uniqueIdentifier: 'total-present-value',
            values: [{
                originalValue: this.goalsTotalPresentValue,
                renderedValue: this.goalsTotalPresentValue?.toString(),
                isBlank: false
            }, {
                originalValue: this.proposalGoalsTotalPresentValue,
                renderedValue: this.proposalGoalsTotalPresentValue?.toString(),
                renderedSecondaryValue: this.showDeltaBetweenPlans ? this.differenceInGoalsTotalPresentValue?.toString() : undefined,
                isBlank: false
            }]
        }];
    }
}