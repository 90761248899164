import {Col, Row} from "react-grid-system";
import React from "react";
import {CHART_TITLE_CELL_WIDTH, TITLE_CELL_WIDTH} from "./ComparePlansContent";
import {formatCurrency} from "../../utils/format";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import PillTextComponent from "./PillTextComponent";
import {COLOR_COMPARE_PLANS_EXCESS, COLOR_COMPARE_PLANS_SHORTFALL} from "../../constants/colors";

interface EstimatedEstateTaxRowProps {
    ComparePlan1EstimatedEstateTax: number;
    ComparePlan2PlanSummaryResponse: PlanSummaryResponse;
    ComparePlan2EstimatedEstateTax: number;
    DifferenceInEstimatedEstateTax: number;
    showPillText?: boolean;
}

const EstimatedEstateTaxRow: React.FC<EstimatedEstateTaxRowProps> = ({
                                                                         ComparePlan1EstimatedEstateTax,
                                                                         ComparePlan2EstimatedEstateTax,
                                                                         ComparePlan2PlanSummaryResponse,
                                                                         DifferenceInEstimatedEstateTax,
                                                                         showPillText
                                                                     }) => {
    return <Row style={{marginTop: 15}}>
        <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
            {ComparePlan2PlanSummaryResponse == null && <hr className='compare-plans-empty-state-hr'/>}
            <div className="compare-plans-sub-header" style={{marginTop: 15}}>Estimated Estate Tax</div>
        </Col>
        <span className="divider"></span>

        <Col md={CHART_TITLE_CELL_WIDTH}>
            <div className="plan-summary-card">
                <div style={{textAlign: "center", marginTop: 15}}>
                    <b>{formatCurrency(ComparePlan1EstimatedEstateTax)}</b>
                </div>
            </div>
        </Col>
        <span className="divider"></span>
        <Col md={CHART_TITLE_CELL_WIDTH}>
            {ComparePlan2PlanSummaryResponse != null &&
                <>
                    <div className="plan-summary-card">
                        <div style={{textAlign: "center", marginTop: 15}}>
                            <b>{formatCurrency(ComparePlan2EstimatedEstateTax ?? 0)}</b>
                            {showPillText && DifferenceInEstimatedEstateTax != 0 &&
                                <PillTextComponent
                                    color={DifferenceInEstimatedEstateTax >= 0 ? COLOR_COMPARE_PLANS_EXCESS : COLOR_COMPARE_PLANS_SHORTFALL}
                                    pillTextValue={DifferenceInEstimatedEstateTax}
                                    className={DifferenceInEstimatedEstateTax >= 0 ? "compare-plans-pill-container pill-container-excess" : "compare-plans-pill-container pill-container-shortfall"}/>
                            }
                        </div>
                    </div>
                </>
            }
        </Col>
    </Row>
}

export default EstimatedEstateTaxRow;