import React, {useState} from "react";
import {Button, UnderlinedHeader} from "src/components";
import PowerOfAppointmentRow from "./PowersOfAppointmentRow";
import {Member, PoaType, PowerOfAppointment, TransferType} from "../EstateFlowchartTypes";

type PowersOfAppointmentSectionProps = {
    powersOfAppointment: PowerOfAppointment[]
    updateMaritalTrust: (newPowersOfAppointments: PowerOfAppointment[]) => void,
    familyMembers: Member[]
}
export default function PowersOfAppointmentSection({
                                                       powersOfAppointment,
                                                       updateMaritalTrust,
                                                       familyMembers
                                                   }: PowersOfAppointmentSectionProps): JSX.Element {

    const [powersOfAppointments, setPowersOfAppointments] = useState<PowerOfAppointment[]>(powersOfAppointment);

    const onAddPowerOfAppointment = () => {
        const numberOfPoa = powersOfAppointments.length;
        const newPowerOfAppointment: PowerOfAppointment = {
            type: PoaType.Limited,
            transferType: TransferType.Testamentary,
            powerHolder: "",
            permissibleAppointees: "",
            additionalDetails: "",
            poaOrder: numberOfPoa,
        }
        const updatedListOfPowersOfAppointment = [...powersOfAppointments, newPowerOfAppointment];
        setPowersOfAppointments(updatedListOfPowersOfAppointment);
    }

    const onChangePowerOfAppointment = (index: number, updatedPowerOfAppointment: PowerOfAppointment) => {
        const updatedPowersOfAppointment = powersOfAppointments.map((existingPowerOfAppointment, i) =>
            i === index ? updatedPowerOfAppointment : existingPowerOfAppointment);
        setPowersOfAppointments(updatedPowersOfAppointment);
        updateMaritalTrust(updatedPowersOfAppointment);
    }

    return (
        <section className="powers-of-appointment">
            <UnderlinedHeader
                leftAlignedContent={
                    <h4>Powers of Appointment</h4>
                }
                rightAlignedContent={
                    <Button
                        onClick={() => {
                            onAddPowerOfAppointment()
                        }}
                        icon="left"
                        iconName="add"
                        kind="borderless"
                    >
                        ADD POWERS OF APPOINTMENT
                    </Button>
                }
            />


            {powersOfAppointments.length > 0 ?
                powersOfAppointments.map((powerOfAppointmentForm, index) => (
                    <PowerOfAppointmentRow
                        key={index}
                        index={index}
                        powerOfAppointmentForm={powerOfAppointmentForm}
                        onChangePOA={(updatedPOA: PowerOfAppointment) => {
                            onChangePowerOfAppointment(index, updatedPOA);
                        }}
                        familyMembers={familyMembers}
                    />
                )) : null}

            {powersOfAppointments.length > 0 ? (
                <>
                    <UnderlinedHeader/>
                    <Button
                        onClick={() => {
                            onAddPowerOfAppointment();
                        }}
                        icon="left"
                        iconName="add"
                        kind="borderless"
                    >
                        ADD POWERS OF APPOINTMENT
                    </Button>
                </>
            ) : null}


        </section>

    )
}