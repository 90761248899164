import {Col, Row} from "react-grid-system";
import React from "react";
import {CHART_TITLE_CELL_WIDTH, TITLE_CELL_WIDTH} from "./ComparePlansContent";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import {COLOR_COMPARE_PLANS_EXCESS, COLOR_COMPARE_PLANS_SHORTFALL} from "../../constants/colors";

interface ReserveTargetLengthRowProps {
    comparePlan1PortfolioReserveTargetLength: number | undefined;
    comparePlan2PortfolioReserveTargetLength: number | undefined;
    comparePlan2PlanSummaryResponse: PlanSummaryResponse;
    showPillText?: boolean;
}

const ReserveTargetLengthRow: React.FC<ReserveTargetLengthRowProps> = ({
                                                                           comparePlan1PortfolioReserveTargetLength,
                                                                           comparePlan2PortfolioReserveTargetLength,
                                                                           comparePlan2PlanSummaryResponse,
                                                                           showPillText
                                                                       }) => {
    return <Row style={{marginTop: 15}}>
        <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
            {comparePlan2PlanSummaryResponse == null && <hr className={"compare-plans-empty-state-hr"}/>}
            <div className="compare-plans-sub-header" style={{marginTop: 15}}>Portfolio Reserve Target Length</div>
        </Col>
        <span className="divider"></span>
        <Col md={CHART_TITLE_CELL_WIDTH}>
            <div className="plan-summary-card">
                <div style={{textAlign: "center", marginTop: 15}}>
                    <b>{comparePlan1PortfolioReserveTargetLength ?? 0} years</b>
                </div>
            </div>
        </Col>
        <span className="divider"></span>
        <Col md={CHART_TITLE_CELL_WIDTH}>
            {comparePlan2PlanSummaryResponse != null &&
                <>
                    <div className="plan-summary-card">
                        <div style={{textAlign: "center", marginTop: 15}}>
                            <b>{comparePlan2PortfolioReserveTargetLength ?? 0} years</b>
                            {showPillText && (comparePlan2PortfolioReserveTargetLength! - comparePlan1PortfolioReserveTargetLength!) != 0 &&
                                <span
                                    className={(comparePlan2PortfolioReserveTargetLength! - comparePlan1PortfolioReserveTargetLength!) >= 0 ? "compare-plans-pill-container pill-container-excess position-absolute" : "compare-plans-pill-container pill-container-shortfall position-absolute"}
                                    style={{
                                        color: (comparePlan2PortfolioReserveTargetLength! - comparePlan1PortfolioReserveTargetLength!) >= 0 ? COLOR_COMPARE_PLANS_EXCESS : COLOR_COMPARE_PLANS_SHORTFALL,
                                    }}>
                                <span
                                    className="pill-text"> {(comparePlan2PortfolioReserveTargetLength! - comparePlan1PortfolioReserveTargetLength!) + " years"}
                                </span>
                            </span>
                            }
                        </div>
                    </div>
                </>
            }
        </Col>
    </Row>
}

export default ReserveTargetLengthRow;