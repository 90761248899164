import React, {ChangeEvent} from "react";
import {DropdownItem, PresentationPaneHeader} from "../../components";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import PageActionMenu from "../../components/ActionMenu/PageActionMenu";

interface ComparePlansHeaderProps {
    value: boolean;
    onChange: (isChecked: boolean) => void;
    selectedValue: boolean;
}

const ComparePlansHeader: React.FC<ComparePlansHeaderProps> = ({value, onChange, selectedValue}) => {
    const {id} = useParams<RouteWithId>();
    const handleShowDeltaBetweenPlans = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked);
    }
    const displayName = useProfileAndProposals(id).approvedProfile.displayName
    const titleRowChildren = () => {
        return (
            <div className="compare-plans-header">
                <PageActionMenu data-testid="show-delta-between-plans" className={"three-dots-menu"} panelWidth={220}
                                aria-label="show-delta-between-plans">
                    <DropdownItem>
                        Show Delta between Plans
                        <input
                            id="showDeltaBetweenPlans"
                            name="show-delta-between-plans"
                            type="checkbox"
                            disabled={!selectedValue}
                            aria-label="show-delta-between-plans"
                            key={"show-delta-between-plans"}
                            style={{marginLeft: '10px'}}
                            onClick={(e) => e.stopPropagation()}
                            onChange={handleShowDeltaBetweenPlans}
                        />
                    </DropdownItem>
                </PageActionMenu>
            </div>
        );
    };

    return (
        <div>
            <PresentationPaneHeader
                displayName={displayName}
                title="Compare Plans"
                titleRow={titleRowChildren()}
            />
        </div>
    );
}
export default ComparePlansHeader;