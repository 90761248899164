import {Col, Row} from "react-grid-system";
import {BarchartUnderlay} from "../../ClientManagement/AssetReliance/BarChart/BarChartUnderlay";
import React from "react";
import {hasPositiveExcessAssets} from "../../ClientManagement/AssetReliance/AssetRelianceUtil";
import ChartStatusLine from "../../ClientManagement/AssetReliance/ChartStatusLine";
import {COLOR_GOALS, COLOR_NT_ORANGE_800} from "../../constants/colors";
import {StackedBarChart} from "../../components";
import ExcessAssetsVerticalLine from "../../ClientManagement/AssetReliance/ExcessAssetsVerticalLine";
import _ from "lodash";
import {roundUpToNext} from "../../utils/format";
import ComparePlanAssetBarChartColumn
    from "../../ClientManagement/AssetReliance/BarChart/ComparePlanAssetBarChartColumn";
import {CHART_CELL_WIDTH, TITLE_CELL_WIDTH} from "./ComparePlansContent";

type ComparePlansBarchartContainerProps = {
    barChartColWidth: number,
    totalStacks: number,
    netAssetsComparePlan1: number,
    totalGoalsComparePlan1: number,
    excessAssetsComparePlan1: number,
    netAssetsComparePlan2: number,
    totalGoalsComparePlan2: number,
    excessAssetsComparePlan2: number
}

export const ComparePlansBarchartContainer: React.FC<ComparePlansBarchartContainerProps> = ({
                                                                                                barChartColWidth,
                                                                                                totalStacks,
                                                                                                netAssetsComparePlan1,
                                                                                                totalGoalsComparePlan1,
                                                                                                excessAssetsComparePlan1,
                                                                                                netAssetsComparePlan2,
                                                                                                totalGoalsComparePlan2,
                                                                                                excessAssetsComparePlan2
                                                                                            }) => {
    const minimumValueToApplyGraphRounding = 1_000_000;
    let barchartMaxValueForCompare1 = totalGoalsComparePlan1 >= netAssetsComparePlan1 ? totalGoalsComparePlan1 : netAssetsComparePlan1;
    let barchartMaxValueForCompare2 = totalGoalsComparePlan2 >= netAssetsComparePlan2 ? totalGoalsComparePlan2 : netAssetsComparePlan2;

    const chartUnderlayMaxValue = (Math.max(barchartMaxValueForCompare1, barchartMaxValueForCompare2) >= minimumValueToApplyGraphRounding)
        ? roundUpToNext(Math.max(barchartMaxValueForCompare1, barchartMaxValueForCompare2), 5)
        : minimumValueToApplyGraphRounding;

    return (
        <>
            <Row>
                <Col className="asset-reliance-chart-container">
                    <BarchartUnderlay totalStacksCount={totalStacks}
                                      width={barChartColWidth}
                                      maxValue={chartUnderlayMaxValue}
                                      titleCellWidth={TITLE_CELL_WIDTH}
                                      stackContainerWidth={CHART_CELL_WIDTH}
                                      numberOfColumns={6}
                                      minimumColumn={1}
                                      rightBorderDivider={true}
                                      className = "asset-reliance-barchart-row compare-plans-barchart-row-underlay"
                    />
                    <span className="divider"></span>
                    <Row data-testid="AssetRelianceBarchartRow"
                         className="asset-reliance-barchart-row"
                         style={{height: "100%"}}>
                        <Col className="asset-reliance-chart-container">
                            {_.range(Math.ceil(totalStacks / 2)).map((index) => {
                                const isAssetSufficient: boolean = hasPositiveExcessAssets(excessAssetsComparePlan1);
                                const assetBarChart = new ComparePlanAssetBarChartColumn(netAssetsComparePlan1, totalGoalsComparePlan1, excessAssetsComparePlan1, chartUnderlayMaxValue, isAssetSufficient)
                                const columnHeightPercentage = (assetBarChart.getColumnMaxValue() / chartUnderlayMaxValue * 100).toFixed(2);

                                return <React.Fragment key={`ExcludedAssetBarChart` + index}>
                                    <Col style={{
                                        width: `${CHART_CELL_WIDTH}px`,
                                        height: `${columnHeightPercentage}%`,
                                        alignSelf: "flex-end"
                                    }}
                                    >
                                        <ChartStatusLine label=''
                                                         width={`${CHART_CELL_WIDTH}px`}
                                                         verticalOffset={assetBarChart.getGoalsPositionVerticalOffset()}
                                                         lineColor={COLOR_GOALS}
                                                         marginLeft={140}
                                                         textColor={COLOR_NT_ORANGE_800}
                                                         id={"barChartOverlay"}
                                                         isAssetReliance={true}
                                        />
                                        <div data-testid={"AssetRelianceStackBarchart"}
                                             className="compareplan_barchart-container">
                                            <StackedBarChart
                                                showLabels={false}
                                                chartWidth={CHART_CELL_WIDTH}
                                                height = {columnHeightPercentage}
                                                top={`${100 - parseFloat(columnHeightPercentage)}`}
                                                className="compare-plans-asset-bar-chart"
                                                data={assetBarChart.getChartItems()}
                                            />
                                            <ExcessAssetsVerticalLine color={assetBarChart.getPillColor()}
                                                                      bottomPercentage={assetBarChart.getVerticalLineMargins().bottomMargin}
                                                                      excessAssets={excessAssetsComparePlan1}
                                                                      topPercentage={assetBarChart.getVerticalLineMargins().topMargin}
                                                                      classname={"compare-plans-asset-line-stack-one"}
                                            />
                                        </div>
                                    </Col>
                                </React.Fragment>
                            })}
                        </Col>
                        <Col className="asset-reliance-chart-container">
                            {_.range(Math.ceil(totalStacks / 2), totalStacks).map((index) => {
                                const isAssetSufficient: boolean = hasPositiveExcessAssets(excessAssetsComparePlan2);
                                const assetBarChart = new ComparePlanAssetBarChartColumn(netAssetsComparePlan2, totalGoalsComparePlan2, excessAssetsComparePlan2, chartUnderlayMaxValue, isAssetSufficient)
                                const columnHeightPercentage = (assetBarChart.getColumnMaxValue() / chartUnderlayMaxValue * 100).toFixed(2);

                                return <React.Fragment key={`ExcludedAssetBarChart` + index}>
                                    {netAssetsComparePlan2 ? <Col style={{width: `${CHART_CELL_WIDTH}px`, height: `${columnHeightPercentage}%`, alignSelf: "flex-end"}}>
                                        <ChartStatusLine label=''
                                                         width={`${CHART_CELL_WIDTH}px`}
                                                         verticalOffset={assetBarChart.getGoalsPositionVerticalOffset()}
                                                         lineColor={COLOR_GOALS}
                                                         marginLeft={-60}
                                                         textColor={COLOR_NT_ORANGE_800}
                                                         id={"barChartOverlay"}
                                                         isAssetReliance={true}
                                        />
                                        <div data-testid={"AssetRelianceStackBarchart"}
                                             className="compareplan_barchart-container">
                                            <StackedBarChart
                                                showLabels={false}
                                                height = {columnHeightPercentage}
                                                top={`${100 - parseFloat(columnHeightPercentage)}`}
                                                chartWidth={CHART_CELL_WIDTH}
                                                data={assetBarChart.getChartItems()}
                                            />
                                            <ExcessAssetsVerticalLine color={assetBarChart.getPillColor()}
                                                                      bottomPercentage={assetBarChart.getVerticalLineMargins().bottomMargin}
                                                                      excessAssets={excessAssetsComparePlan2}
                                                                      topPercentage={assetBarChart.getVerticalLineMargins().topMargin}
                                                                      classname={"compare-plans-asset-line"}
                                            />
                                        </div>
                                    </Col> : <div className='compare-plans-empty-state'> Select a plan to compare</div>}
                                </React.Fragment>
                            })}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}