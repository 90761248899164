import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../components";
import {formatCurrencyRounded} from "../../utils/format";


export class NetAssetsTableDisplayForComparePlan extends TableDisplay<number, TableRow<number>> {
    private readonly netAssets: number;
    private readonly proposalNetAssets: number;
    private readonly differenceInNetAssets: number | String;
    private readonly showDeltaBetweenPlans: boolean;
    private readonly isEmptyState: boolean;

    constructor(columnCounter: ColumnCounter, netAssets: number, proposalNetAssets: number, differenceInNetAssets: number | String, showDeltaBetweenPlans: boolean, isEmptyState: boolean) {
        super(columnCounter);
        this.netAssets = netAssets;
        this.proposalNetAssets = proposalNetAssets;
        this.differenceInNetAssets = differenceInNetAssets;
        this.showDeltaBetweenPlans = showDeltaBetweenPlans;
        this.isEmptyState = isEmptyState;
    }

    protected get headerLabel(): string {
        return "Net Assets";
    }

    protected get headerValues(): TableCell<number>[] {
        const profileNetAssets = {
            originalValue: this.netAssets,
            renderedValue: formatCurrencyRounded(this.netAssets, 0),
            isBlank: false
        }
        const proposalNetAssets = {
            originalValue: this.proposalNetAssets,
            renderedValue: formatCurrencyRounded(this.proposalNetAssets, 0),
            renderedSecondaryValue: this.showDeltaBetweenPlans ? this.differenceInNetAssets?.toString() : undefined,
            isBlank: false
        }
        return this.isEmptyState ? [profileNetAssets] : [profileNetAssets, proposalNetAssets];
    }

    get rows(): TableRow<number>[] {
        return [{
            accentColor: '',
            children: [],
            label: "Total Present Value",
            uniqueIdentifier: 'total-present-value',
            values: [{
                originalValue: this.netAssets,
                renderedValue: this.netAssets?.toString(),
                isBlank: true
            }, {
                originalValue: this.proposalNetAssets,
                renderedValue: this.proposalNetAssets?.toString(),
                renderedSecondaryValue: this.showDeltaBetweenPlans ? this.differenceInNetAssets?.toString() : undefined,
                isBlank: true
            }]
        }];
    }
}