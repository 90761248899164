import React, {ChangeEvent, useEffect, useState} from 'react'
import {
    Dropdown,
    DropdownItem,
    Icon,
    Input,
    RadioGroup,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from 'src/components'
import {
    Beneficiaries,
    FamilyMembers,
    MaritalTrustType,
    Member,
    PowerOfAppointment,
    Trustee,
    TrusteeType
} from '../EstateFlowchartTypes'
import useProfileEditableState from 'src/hooks/useProfileEditableState'
import {isCustomMember, parseFamilyMembers} from './validations'
import {MemberGroup} from 'src/ClientManagement/models/InvestorGroupType'
import {clientManagementApiClient} from 'src/ClientManagement/ClientManagementApiClient'
import {useParams} from 'react-router-dom'
import {RouteWithId} from 'src/routes/types'
import SuccessorTable from './SuccessorTable'
import MemberDropdownMultiselect from './MemberDropdownMultiselect'
import PowersOfAppointmentSection from './PowersOfAppointmentSection';

type MaritalTrustFormProps = {
    beneficiariesInformation: Beneficiaries,
    saveHasBeenClicked: boolean,
    isValid: any,
    updateBeneficiaryInformation: Function
}


/**
 * MaritalTrustForm component is responsible for rendering a form to manage the details of a marital trust.
 * It allows users to input and update information about the trust, including trustees, beneficiaries, funding, distributions, and termination details.
 *
 * @component
 * @param {MaritalTrustFormProps} props - The properties passed to the component.
 * @param {BeneficiariesInformation} props.beneficiariesInformation - Information about the beneficiaries.
 * @param {boolean} props.saveHasBeenClicked - Indicates if the save button has been clicked.
 * @param {Function} props.isValid - Function to validate form fields.
 * @param {Function} props.updateBeneficiaryInformation - Function to update beneficiary information.
 *
 * @returns {JSX.Element} The rendered MaritalTrustForm component.
 *
 * @example
 * <MaritalTrustForm
 *   beneficiariesInformation={beneficiariesInformation}
 *   saveHasBeenClicked={saveHasBeenClicked}
 *   isValid={isValid}
 *   updateBeneficiaryInformation={updateBeneficiaryInformation}
 * />
 */
function MaritalTrustForm({
                              beneficiariesInformation,
                              saveHasBeenClicked,
                              isValid,
                              updateBeneficiaryInformation
                          }: MaritalTrustFormProps): JSX.Element {
    const [maritalTrust, setMaritalTrust] = useState<MaritalTrustType>(beneficiariesInformation.firstDeath.maritalTrust ||
        {
            trustName: "",
            tags: "",
            trustees: [] as Trustee[],
            successors: [] as Trustee[],
            beneficiary: [] as Trustee[],
            funding: "",
            distributions: "",
            termination: "",
            powersOfAppointment: [] as PowerOfAppointment[]
        })
    const [trustees, setTrustees] = useState([{label: "Northern Trust", selected: false, value: ""}] as Member[])
    const [familyMembersResponse, setFamilyMembersResponse] = useState({} as FamilyMembers)
    const [beneficiaryOptions, setBeneficiaryOptions] = useState([] as Member[])
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const {id} = useParams<RouteWithId>();
    const dropdownHeight: number = trustees.length * 34 < 280 ? (trustees.length * 34) + 20 : 280

    const [familyMembers, setFamilyMembers] = useState({} as MemberGroup)

    const getFamilyMembers = async (): Promise<MemberGroup> => {
        return clientManagementApiClient.getMemberGroup(id);
    }

    const onChangeTrusteeDropdown = (selectedOptions: any) => {
        const members: Trustee[] = [];
        for (const element of selectedOptions) {
            const member: Trustee = {
                trustId: "",
                memberType: TrusteeType.BeneficiaryTrustee,
                familyMember: true,
                customName: ""
            };
            member.memberType = TrusteeType.BeneficiaryTrustee;
            member.trustId = "";
            if (element.hasOwnProperty("icon")) { // only for new custom
                member.customName = element.label;
                member.familyMember = false;
            } else { // family members and previous custom
                member.familyMember = !isCustomMember(element.value, familyMembersResponse);
                member.memberId = element.value;
                if (isCustomMember(element.value, familyMembersResponse)) {
                    member.customName = element.label;
                } else {
                    member.customName = "";
                }
            }
            members.push(member);
        }
        setMaritalTrust({...maritalTrust, trustees: members});
    }

    const sortedPowersOfAppointments = (): PowerOfAppointment[] => {
        return [...maritalTrust.powersOfAppointment].sort((a, b) => a.poaOrder - b.poaOrder)
    }

    const updatePowersOfAppointments = (newPowersOfAppointments: PowerOfAppointment[]) => {
        setMaritalTrust({...maritalTrust, powersOfAppointment: newPowersOfAppointments});
    }

    useEffect(() => {
        let isMounted = true;
        getFamilyMembers().then((res) => {
            const parsedFamilyMembersResponse: FamilyMembers = parseFamilyMembers(res);
            let item = {label: "", value: "", selected: false};
            item.label = parsedFamilyMembersResponse.label;
            item.value = parsedFamilyMembersResponse.value;
            parsedFamilyMembersResponse.familyMember.push(item);
            if (isMounted) { // Do not change order of sets
                setBeneficiaryOptions(parsedFamilyMembersResponse.familyMember);
                if (beneficiariesInformation.firstDeath.maritalTrust) {
                    setMaritalTrust(beneficiariesInformation.firstDeath.maritalTrust);
                }
                setFamilyMembers(res);
                setFamilyMembersResponse(parsedFamilyMembersResponse);
                setTrustees(prevArray => [...prevArray, ...parsedFamilyMembersResponse.familyMember]);
            }
        })
        return () => {
            isMounted = false
        };
    }, [id]);

    useEffect(() => {
        updateBeneficiaryInformation({...beneficiariesInformation, firstDeath: {maritalTrust: maritalTrust}})
    }, [maritalTrust, maritalTrust.powersOfAppointment])


    return (
        <section aria-label="Marital Trust Form" className="marital-trust-form">
            <UnderlinedHeader
                leftAlignedContent={
                    <div className="required-header" data-testid={'basicInfo-container'}>
                        <h4>Marital Trust</h4>
                        <Icon name="bank" className={"bank-icon"}/>
                        <RequiredFieldsSubheader/>
                    </div>
                }
            />
            <div className="textalign-right form-main">
                <div className="layout-data-entry-form__field">
                    <label className={"h5"} data-testid={'trust-name'}>Trust Name<RedAsterisk/></label>
                    <Input
                        aria-label="Trust Name"
                        size="medium"
                        value={maritalTrust.trustName || ""}
                        error={saveHasBeenClicked && !isValid(maritalTrust.trustName) ? "Trust Name is required." : undefined}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setMaritalTrust({...maritalTrust, trustName: e.target.value})}
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label className={"h5"}>Tags<RedAsterisk/></label>
                    <RadioGroup
                        name="tags"
                        label=""
                        className='vertical-radiogroup'
                        layout='vertical'
                        values={["Exempt", "Non-Exempt", "Both", "Unknown/Inapplicable"]}
                        disabled={isProfileWithProposalsOrArchived}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const {value} = e.target;
                            setMaritalTrust({...maritalTrust, tags: value})
                        }}
                        required={true}
                        selected={maritalTrust.tags}
                    />
                    {saveHasBeenClicked && !isValid(maritalTrust.tags) ?
                        <div className='input__info color-text--error'>Tags are required.</div> : undefined}
                </div>
                <MemberDropdownMultiselect
                    label="Trustees"
                    required={true}
                    options={familyMembers}
                    selectedOptions={maritalTrust.trustees ? maritalTrust.trustees : []}
                    includeNT={true}
                    onChange={onChangeTrusteeDropdown}
                    onError={saveHasBeenClicked &&
                    (!maritalTrust.trustees || maritalTrust.trustees.length === 0) ? "Trustees are required." : undefined}
                />
                {
                    familyMembersResponse.familyMember && familyMembersResponse.familyMember.length > 0 &&
                    <SuccessorTable formData={maritalTrust}
                                    successorType={TrusteeType.BeneficiarySuccessor}
                                    dropdownHeight={dropdownHeight}
                                    familyMembersResponse={familyMembersResponse}
                                    updateData={setMaritalTrust}/>
                }
                <div className="layout-data-entry-form__field" data-testid={'beneficiary-dropdown'}>
                    <label className={"h5"} aria-label={'beneficiary'}>Beneficiary</label>
                    <Dropdown
                        className='beneficiaryDropdown'
                        onChange={(e) => {
                            setMaritalTrust({
                                ...maritalTrust,
                                beneficiary: [{
                                    trustId: undefined,
                                    memberId: e.value,
                                    memberType: TrusteeType.Beneficiary,
                                    familyMember: true,
                                    customName: "",
                                    memberOrder: undefined
                                }]
                            })
                        }}
                        size='medium'
                        iconNameClose="arrow_drop_up"
                        iconNameOpen="arrow_drop_down"
                        id="beneficiary"
                        name="beneficiary"
                        value={maritalTrust.beneficiary && maritalTrust.beneficiary.length > 0 ? maritalTrust.beneficiary[0].memberId : ""}
                        alignRight={false}
                        nativeOnMobile={false}
                        dropUp={false}
                        virtualScroll={false}
                        panelHeight={beneficiaryOptions.length * 34 < 280 ? (beneficiaryOptions.length * 34) + 20 : 280}
                    >

                        {beneficiaryOptions.map((member: Member) => (
                            <DropdownItem
                                key={member.value}
                                value={member.value}
                                itemText={member.label}
                            />
                        ))}
                    </Dropdown>
                </div>
                <div className="layout-data-entry-form__field basic-information__funding">
                    <div className={"textarea-label"}><label className={"h5"}>Funding</label>
                        <span
                            className='textarea-limit-count'>{maritalTrust.funding ? maritalTrust.funding.length : 0}/500</span>
                    </div>
                    <textarea
                        name="Funding"
                        className="textarea-500-limit input-skin"
                        data-testid='funding-field'
                        autoFocus={false}
                        rows={5}
                        cols={50}
                        maxLength={500}
                        defaultValue={maritalTrust.funding}
                        onChange={(e) => {
                            setMaritalTrust({
                                ...maritalTrust, funding: e.target.value
                            })
                        }
                        }
                    />
                </div>
                <div className="layout-data-entry-form__field basic-information__distribution">
                    <div className={"textarea-label"}><label className={"h5"}>Distributions<RedAsterisk/></label>
                        <span
                            className='textarea-limit-count'>{maritalTrust.distributions ? maritalTrust.distributions.length : 0}/500</span>
                    </div>
                    <textarea
                        name="Distributions"
                        className={saveHasBeenClicked && !isValid(maritalTrust.distributions) ? "textarea-500-limit input-skin error" : "textarea-500-limit input-skin"}
                        data-testid='distribution-field'
                        autoFocus={false}
                        rows={5}
                        cols={50}
                        maxLength={500}
                        defaultValue={maritalTrust.distributions}
                        onChange={(e) => {
                            setMaritalTrust({
                                ...maritalTrust, distributions: e.target.value
                            })
                        }
                        }
                    />
                    {saveHasBeenClicked && !isValid(maritalTrust.distributions) ?
                        <div className='input__info color-text--error'>Distributions are required.</div> : undefined}
                    <span
                        className="input__info margintop-sm">{"Categories can include: emergencies, medical needs, health, education, " +
                        "support & maintenance, comfort, best interest & welfare, pleasure/happiness, " +
                        "trustee's sole discretion, etc." + "for e.g., Mandatory income and discretionary principal " +
                        "in the trustee's discretion for support in reasonable comfort and health"}</span>
                </div>
                <div className="layout-data-entry-form__field basic-information__termination">
                    <div className={"textarea-label"}><label className={"h5"}>Termination</label>
                        <span
                            className='textarea-limit-count'>{maritalTrust.termination ? maritalTrust.termination.length : 0}/500</span>
                    </div>
                    <textarea
                        name="Termination"
                        className="textarea-500-limit input-skin"
                        data-testid='termination-field'
                        autoFocus={false}
                        rows={5}
                        cols={50}
                        maxLength={500}
                        defaultValue={maritalTrust.termination}
                        onChange={(e) => {
                            setMaritalTrust({
                                ...maritalTrust, termination: e.target.value
                            })
                        }
                        }
                    />
                </div>

                {<PowersOfAppointmentSection powersOfAppointment={sortedPowersOfAppointments()}
                                             familyMembers={beneficiaryOptions}
                                             updateMaritalTrust={updatePowersOfAppointments}
                />}
            </div>

        </section>
    )
}

export default MaritalTrustForm