import React, {useEffect, useState} from "react";
import {Col, Row} from "react-grid-system";
import classNames, {Mapping as ClassNamesMapping} from "classnames";
import {Icon} from '../Icon';
import {TableRow} from "./TableDisplay";
import {COLOR_COMPARE_PLANS_EXCESS, COLOR_COMPARE_PLANS_SHORTFALL, COLOR_NT_GREY_550} from "../../constants/colors";
import {useAppSelector} from "../../store/hooks";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import PillTextComponent from "../../Plans/CompareScenarios/PillTextComponent";

export interface CellClassParameters {
    isBlank: boolean;
    columnIndex: number;
    rowIndex?: number;
}

interface TableRowProps<ValueType, TR extends TableRow<ValueType>> {
    additionalCellClasses?: (params: CellClassParameters) => ClassNamesMapping[];
    ariaLabel?: string;
    indentTitle?: boolean;
    row: TR;
    onClick?: () => void;
    isSummaryRow?: boolean;
    rowIndex?: number;
    onTitleCellMouseEnter?: (row: TR, rowIndex?: number) => void;
    onTitleCellMouseLeave?: (row: TR) => void;
    valueColumnSize?: number;
    isExpanded: boolean;
    titleCellWidth: number;
    isDraggable?: boolean;
    showPillText?: boolean;
}

const NO_OP_ARRAY = () => [];

export const TableRowComponent = <ValueType, TR extends TableRow<ValueType>>({
                                                                                 additionalCellClasses = NO_OP_ARRAY,
                                                                                 ariaLabel,
                                                                                 indentTitle,
                                                                                 row,
                                                                                 onClick,
                                                                                 isSummaryRow,
                                                                                 rowIndex,
                                                                                 onTitleCellMouseEnter,
                                                                                 onTitleCellMouseLeave,
                                                                                 valueColumnSize,
                                                                                 isExpanded,
                                                                                 titleCellWidth,
                                                                                 isDraggable = false,
                                                                                 showPillText,
                                                                             }: TableRowProps<ValueType, TR>,) => {
    const [icon, setIcon] = useState<string>('');
    const releaseToggles = useAppSelector(selectReleaseToggles);
    let pillTextValue;
    useEffect(() => {
        if (onClick) {
            if (isExpanded) {
                setIcon('chevron_down');
            } else {
                setIcon('chevron_right');
            }
        } else {
            setIcon('');
        }
    }, [onClick, isExpanded]);

    return (
        <div className="table-row-container">
            <Row className={classNames("row", {"summary-row": isSummaryRow})}
                 role={`row ${!!onClick ? 'button' : ''}`}
                 aria-label={ariaLabel}
                 style={{flexFlow: "nowrap"}}
                 onClick={() => {
                     if (onClick) {
                         onClick();
                     }
                 }}>
                <Col role="column" className={classNames("col title", additionalCellClasses({
                    isBlank: false,
                    columnIndex: -1,
                    rowIndex: rowIndex,
                }))}
                     width={titleCellWidth}
                     style={{minWidth: titleCellWidth}}
                     onMouseEnter={() => {
                         if (onTitleCellMouseEnter) {
                             onTitleCellMouseEnter(row, rowIndex);
                         }
                     }}
                     onMouseLeave={() => {
                         if (onTitleCellMouseLeave) {
                             onTitleCellMouseLeave(row);
                         }
                     }}
                >
                    <RowAccent color={row.accentColor}/>
                    {releaseToggles?.enableUpdatesToEditAssetReliance && isDraggable &&
                        <Icon data-testid={'drag_vertical'} className={"drag_icon"} name={'drag_vertical'}
                              size="large"/>}
                    <Icon name={icon} size="large"/>
                    <div className={classNames("table-row-title-container", {"indent-title": indentTitle})}>
                        <div className={`table-row-title ${row.labelClassName}`}>
                            {row.label}
                            {row.labelSuffix &&
                                <span className="marginleft-sm"
                                      style={{
                                          fontStyle: 'italic',
                                          color: COLOR_NT_GREY_550
                                      }}
                                >{row.labelSuffix}</span>}
                        </div>
                        {row.subtitle && <div className="sub-title">{row.subtitle}</div>}
                        {row.secondaryLabel &&
                            <div className="secondary-label paddingtop-xs">{row.secondaryLabel}</div>}
                    </div>
                </Col>
                {row.values.map((cellValue, columnIndex) => {
                    const {
                        originalValue,
                        renderedValue,
                        renderedSecondaryValue,
                        isBlank,
                        className
                    } = cellValue;
                    pillTextValue = Number(renderedSecondaryValue);
                    return <Col role="column" key={`${columnIndex}-${originalValue}`}
                                className={classNames("col",
                                    {"empty-cell": isBlank},
                                    additionalCellClasses({
                                        isBlank: isBlank,
                                        columnIndex: columnIndex,
                                        rowIndex: rowIndex
                                    }),
                                    className,
                                )}
                                md={valueColumnSize ?? 1.5}>
                        {!showPillText && !pillTextValue &&
                            <div>
                                <div className='rendered-value'>{renderedValue}</div>
                                {renderedSecondaryValue &&
                                    <div className='rendered-value paddingtop-12'>{renderedSecondaryValue}</div>}
                            </div>
                        }
                        {showPillText &&
                            <div className={"pill-container-row"}>
                                <div className='rendered-value-compare-plans'>{renderedValue}</div>
                                {renderedSecondaryValue && renderedSecondaryValue !== '0' &&
                                    <>
                                        <PillTextComponent
                                            color={pillTextValue >= 0 ? COLOR_COMPARE_PLANS_EXCESS : COLOR_COMPARE_PLANS_SHORTFALL}
                                            pillTextValue={pillTextValue}
                                            className={pillTextValue >= 0 ? "compare-plans-pill-container pill-container-excess" : "compare-plans-pill-container pill-container-shortfall"}/></>
                                }
                            </div>
                        }
                    </Col>
                })}
            </Row>
        </div>)
}

interface RowAccentProps {
    color: string;
}

const RowAccent: React.FC<RowAccentProps> = ({color}) => {
    return (
        <div
            aria-label="accordion accent color"
            className="accent"
            style={{backgroundColor: color}}
        />
    )
}