import React from "react";
import {Col, Row} from "react-grid-system";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {formatCurrency} from "../../utils/format";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import {CHART_TITLE_CELL_WIDTH, TITLE_CELL_WIDTH} from "./ComparePlansContent";
import {COLOR_COMPARE_PLANS_EXCESS, COLOR_COMPARE_PLANS_SHORTFALL} from "../../constants/colors";
import PillTextComponent from "./PillTextComponent";

interface NetWorthOverTimeRowProps {
    comparePlan1PlanSummaryResponse: PlanSummaryResponse;
    comparePlan2PlanSummaryResponse: PlanSummaryResponse;
    comparePlan1GraphOptions: Highcharts.Options;
    comparePlan2GraphOptions: Highcharts.Options;
    differenceInFutureValueOfExcessAssets: number;
    showPillText?: boolean;
}

const NetWorthOverTimeRow: React.FC<NetWorthOverTimeRowProps> = ({
                                                                     comparePlan1PlanSummaryResponse,
                                                                     comparePlan2PlanSummaryResponse,
                                                                     comparePlan1GraphOptions,
                                                                     comparePlan2GraphOptions,
                                                                     differenceInFutureValueOfExcessAssets,
                                                                     showPillText
                                                                 }) => {
    return (
        <Row style={{marginTop: 15}}>
            <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
                <div className="compare-plans-sub-header">Net Worth Over Time</div>
                <div className='subhead'>Expected excess at the end of the planning period</div>
            </Col>
            <span className="divider"></span>
            <Col md={CHART_TITLE_CELL_WIDTH}>
                <div className="plan-summary-card">
                    {comparePlan1PlanSummaryResponse.excessAssets > 0 ?
                        <>
                            <div style={{textAlign: "center"}}>
                                {formatCurrency(comparePlan1PlanSummaryResponse.futureValueOfExcessAssets)}
                            </div>
                            <HighchartsReact highcharts={Highcharts} options={comparePlan1GraphOptions}/>
                        </>
                        : <>
                            <div className="asset_insufficiency_empty_state"> Net Worth Over Time cannot be displayed
                                when there is an asset shortfall.
                            </div>
                        </>
                    }
                </div>
            </Col>
            <span className="divider"></span>
            <Col md={CHART_TITLE_CELL_WIDTH}>
                <div className="plan-summary-card">
                    {comparePlan2PlanSummaryResponse != null &&
                        <>
                            {comparePlan2PlanSummaryResponse.excessAssets > 0 &&
                                <>
                                    <div style={{textAlign: "center"}}>
                                        {formatCurrency(comparePlan2PlanSummaryResponse.futureValueOfExcessAssets)}
                                        {showPillText
                                            && (differenceInFutureValueOfExcessAssets) != 0
                                            && (comparePlan1PlanSummaryResponse.excessAssets > 0
                                                && comparePlan2PlanSummaryResponse.excessAssets > 0) &&
                                            <PillTextComponent
                                                color={(differenceInFutureValueOfExcessAssets) >= 0 ? COLOR_COMPARE_PLANS_EXCESS : COLOR_COMPARE_PLANS_SHORTFALL}
                                                pillTextValue={(differenceInFutureValueOfExcessAssets)}
                                                className={(differenceInFutureValueOfExcessAssets) >= 0 ? "compare-plans-pill-container pill-container-excess " : "compare-plans-pill-container pill-container-shortfall"}/>
                                        }
                                    </div>
                                    <HighchartsReact highcharts={Highcharts} options={comparePlan2GraphOptions}/>
                                </>
                            }
                            {comparePlan2PlanSummaryResponse.excessAssets <= 0 &&
                                <>
                                    <div className="asset_insufficiency_empty_state"> Net Worth Over Time cannot be
                                        displayed
                                        when there is an asset shortfall.
                                    </div>
                                </>
                            }
                        </>
                    }

                </div>
            </Col>
        </Row>
    );
}

export default NetWorthOverTimeRow;